import React, { useContext, useEffect, useRef } from "react";
import withDraggable from "../HOC/withDraggable";
import { store } from "../../redux/store";
import { Theme } from "../../App";

let dragging = false;
let dragging_widget = null;
let window_widget = null;

function Header({ windowRef, windowKey, icon, headerText, className, menu }) {
  return (
    <div className="">
      <div className={`flex h-[1rem] select-none ${className}`}>
        <img
          className="ml-1 my-auto"
          src={icon}
          alt="terminal icon"
          width={25}
        />
        <span className="text-[#5e5e5e] text-[0.6rem] font-bold m-auto">
          {headerText}
        </span>
        <span className="flex gap-[0.4rem] mr-1">
          <span
            className="w-[0.7rem] h-[0.7rem] bg-white my-auto rounded-full hover:opacity-80 cursor-pointer flex justify-center items-center group"
            style={{ backgroundColor: "rgba(80, 80, 80, 0.98)" }}
            onClick={(e) => {
              // store.dispatch({
              //   type: "updateWindow",
              //   windowKey: windowKey,
              //   window: {
              //     size: { width: "100%", height: "95%" },
              //     pos: { x: 0, y: 20 },
              //   },
              // });
              store.dispatch({
                type: "removeWindow",
                windowKey: windowKey,
              });
              // windowRef.current.style.
              windowRef.current.style.width = "0";
              windowRef.current.style.height = "0";
              windowRef.current.style.top = "100%";
              windowRef.current.style.left = "0px";
              setInterval(() => {
                windowRef.current.style.display = "none";
              }, 300);
            }}
          >
            <svg
              className="opacity-0 group-hover:opacity-80"
              width="7"
              height="7"
              viewBox="0 0 15 15"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="0"
                y1="8"
                x2="15"
                y2="8"
                stroke="white"
                stroke-width="2"
              />
            </svg>
          </span>
          <span
            className="w-[0.7rem] h-[0.7rem] bg-white my-auto rounded-full hover:opacity-80 cursor-pointer flex justify-center items-center group"
            style={{ backgroundColor: "rgba(80, 80, 80, 0.98)" }}
            onClick={(e) => {
              if (windowRef.current.style.width === "100%"){

                store.dispatch({
                  type: "updateWindow",
                  windowKey: windowKey,
                  window: {
                    size: { width: "600px", height: "50%" },
                    pos: { x: 100, y: 100 },
                  },
                });
                // windowRef.current.style.
                windowRef.current.style.width = "600px";
                windowRef.current.style.height = "50%";
                windowRef.current.style.top = "100px";
                windowRef.current.style.left = "100px";
              }else{
                store.dispatch({
                  type: "updateWindow",
                  windowKey: windowKey,
                  window: {
                    size: { width: "100%", height: "95%" },
                    pos: { x: 0, y: 20 },
                  },
                });
                // windowRef.current.style.
                windowRef.current.style.width = "100%";
                windowRef.current.style.height = "95%";
                windowRef.current.style.top = "20px";
                windowRef.current.style.left = "0px";
              }
            }}
          >
            <svg
              className="opacity-0 group-hover:opacity-80"
              width="7"
              height="7"
              viewBox="0 0 15 15"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="0"
                y1="0"
                x2="15"
                y2="0"
                stroke="white"
                stroke-width="4"
              />
              <line
                x1="0"
                y1="0"
                x2="0"
                y2="15"
                stroke="white"
                stroke-width="4"
              />
              <line
                x1="15"
                y1="15"
                x2="15"
                y2="0"
                stroke="white"
                stroke-width="4"
              />
              <line
                x1="15"
                y1="15"
                x2="0"
                y2="15"
                stroke="white"
                stroke-width="4"
              />
            </svg>
          </span>
          <span
            className="w-[0.7rem] h-[0.7rem] bg-blue-700 my-auto rounded-full flex items-center justify-center hover:opacity-80 cursor-pointer"
            onClick={(e) => {
              store.dispatch({ type: "removeWindow", windowKey: windowKey });
              // windowRef.current.remove()
              windowRef.current.style.display = "none";
            }}
          >
            <div
              className="m-auto w-[0.125rem] h-[0.125rem] bg-black rounded-full"
              style={{ backgroundColor: "rgba(20,20,20, 0.98)" }}
            ></div>
          </span>
        </span>
      </div>

      <div className="flex ml-2 gap-2 h-[1rem] text-[0.7rem] text-white select-none">
        {menu}
      </div>
    </div>
  );
}

const DraggableHeader = withDraggable(Header);

function Window({
  id,
  pos,
  init_size,
  icon = "",
  headerText = "Window",
  menu = [],
  MainView = () => <></>,
}) {
  useEffect(() => {
    windowRef.current.style.top = `${pos.y}px`;
    windowRef.current.style.left = `${pos.x}px`;
    windowRef.current.style.zIndex = 10;
  }, []);

  const size = init_size || { width: "600px", height: "50%" };

  const windowRef = useRef(null);
  const resizeRef = useRef(null);

  const { theme } = useContext(Theme);

  useEffect(() => {
    const resizeWidget = resizeRef.current;
    document.addEventListener("mouseup", (e) => {
      dragging = false;
      dragging_widget = null;
      window_widget = null;
    });

    resizeWidget.addEventListener("mousedown", (e) => {
      dragging = true;
      dragging_widget = resizeWidget;
      window_widget = windowRef.current;
    });

    document.addEventListener("mousemove", (e) => {
      if (!dragging) return;

      const mouseX = e.clientX;
      const mouseY = e.clientY;

      let newWidth = Math.max(
        mouseX - parseInt(window_widget.style.left) + 5,
        300
      );
      let newHeight = Math.max(
        mouseY - parseInt(window_widget.style.top) - 25,
        300
      );

      window_widget.style.width = `${newWidth}px`;
      window_widget.style.height = `${newHeight}px`;
      window_widget.style.transition = "none";

      store.dispatch({
        type: "updateWindow",
        windowKey: id,
        window: {
          size: { width: `${newWidth}px`, height: `${newHeight}px` },
        },
      });
    });
    store.dispatch({
      type: "updateWindow",
      windowKey: id,
      window: {
        size: { width: size.width, height: size.height },
      },
    });

    windowRef.current.style.width = `${size.width}`;
    windowRef.current.style.height = `${size.height}`;

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (theme === "1") {
      windowRef.current.style.backgroundColor = "rgba(133, 144, 155, 0.6)";
    } else if (theme === "2") {
      windowRef.current.style.backgroundColor = "rgba(30, 30, 30, 0.7)";
    } else if (theme === "3") {
      windowRef.current.style.backgroundColor = "rgba(30, 30, 30, 0.5)";
    } else if (theme === "4") {
      windowRef.current.style.backgroundColor = "rgba(203, 82, 199, 0.3)";
    }
  }, [theme]);

  return (
    <div
      ref={windowRef}
      className={`window absolute rounded backdrop-blur-sm
       border-black border-[1px]
        shadow-black shadow-sm
        transition-all duration-300
      `}
      onMouseDownCapture={(e) => {
        let maxindex = 10;
        document.querySelectorAll(".window").forEach((window) => {
          const zindex = parseInt(window.style.zIndex);
          if (zindex > maxindex) {
            maxindex = zindex;
          }
        });
        windowRef.current.style.zIndex = maxindex + 1;
      }}
    >
      <div className="w-full bg-gradient-to-t from-[#696969] to-[#ffffff] rounded-tl rounded-tr">
        <DraggableHeader
          id={id}
          icon={icon}
          headerText={headerText}
          windowKey={id}
          windowRef={windowRef}
          className={""}
          menu={menu}
        />
      </div>
      <MainView />
      <svg
        ref={resizeRef}
        className="absolute bottom-0 right-0 hover:cursor-nw-resize"
        width="10"
        height="10"
        viewBox="0 0 15 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="0" y1="15" x2="15" y2="0" stroke="white" stroke-width="2" />
        <line x1="8" y1="15" x2="15" y2="8" stroke="white" stroke-width="2" />
      </svg>
    </div>
  );
}

export default Window;
