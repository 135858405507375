import { store } from '../../../../redux/store'
import { Folder } from '../../../../redux/fileSystemINITv2';

export function tabPressed({ path, command, string }) {
    let folder = Folder.getFolder(path)
    let folders = folder.getFoldersNames().filter((item) => item.startsWith(string))
    let files = folder.getFilesNames().filter((item) => item.startsWith(string))

    if (string === "") return ""

    switch (command) {
        case 'cd':
            console.log("posible outputs: ", folders)
            return folders[0] || ""
        case 'rm':
            console.log("posible outputs: ", folders.concat(files))
            return folders.concat(files)[0] || ""
        case 'cat':
            console.log("posible outputs: ", files)
            return files[0] || ""
        default:
            return ""
    }
}

export function instructionHandler({ id, instruction, path, setPath, inputRef, instructions, setInstructions }) {

    // console.log("instruction: "+instruction)
    let root = store.getState().fileSystem;
    console.log(root)

    try {

        switch (instruction.split(" ")[0]) {
            case 'clear':
                store.dispatch({ type: "updateWindow", windowKey: id, window: { instructions: [] } });
                setInstructions([]);
                return
            case 'ls':
                const foldersOutput = Folder.getFolder(path).getFoldersNames().map((folderName) => folderName).join(' ')
                const filesOutput = Folder.getFolder(path).getFilesNames().map((fileName) => fileName).join(' ')

                store.dispatch({
                    type: "updateWindow", windowKey: id, window: {
                        instructions: [
                            ...instructions, { path: path, instruction: instruction, output: `<span><span className='folders-names text-[0.6rem]'>${foldersOutput}</span><span className='files-names text-[0.6rem]'>${filesOutput}</span></span>` }
                        ]
                    }
                });
                setInstructions([...instructions, { path: path, instruction: instruction, output: `<span><span className='folders-names text-[0.6rem]'>${foldersOutput}</span><span className='files-names text-[0.6rem]'>${filesOutput}</span></span>` }])
                return
            case 'cd':
                let data = instruction.split(" ")[1]

                if (data === '..') {
                    let newPath = path.split('/').slice(0, -1).join('/')
                    console.log("set new path: ", newPath)
                    store.dispatch({
                        type: "updateWindow", windowKey: id, window: {
                            instructions: [
                                ...instructions, { path: path, instruction: instruction, output: "" }
                            ],
                            current_path: newPath
                        }
                    });
                    setPath(newPath)
                    setInstructions([...instructions, { path: path, instruction: instruction, output: "" }])
                    return
                }
                let newPath = path + "/" + data
                let folder = Folder.getFolder(newPath)
                if (folder) {
                    store.dispatch({
                        type: "updateWindow", windowKey: id, window: {
                            instructions: [
                                ...instructions, { path: path, instruction: instruction, output: "" }
                            ],
                            current_path: newPath
                        }
                    });
                    setPath(newPath)
                    setInstructions([...instructions, { path: path, instruction: instruction, output: "" }])
                    return
                }
                store.dispatch({
                    type: "updateWindow", windowKey: id, window: {
                        instructions: [
                            ...instructions, { path: path, instruction: instruction, output: "No such file or directory" }
                        ]
                    }
                });
                setInstructions([...instructions, { path: path, instruction: instruction, output: "No such file or directory" }])
                return
            case 'code':
                const key = Math.random().toString(36).substring(7);
                store.dispatch({ type: "addWindow", window: { type: "VSCode", id: key, pos: { x: 50, y: 100 } } })
                setInstructions([...instructions, { path: path, instruction: instruction, output: "" }])
                return
            case 'mkdir':
                let folderName = instruction.split(" ")[1]
                if (folderName === undefined) {
                    store.dispatch({
                        type: "updateWindow", windowKey: id, window: {
                            instructions: [
                                ...instructions, { path: path, instruction: instruction, output: "mkdir: missing operand" }
                            ]
                        }
                    });
                    setInstructions([...instructions, { path: path, instruction: instruction, output: "mkdir: missing operand" }])
                    return
                }
                Folder.getFolder(path).addFolder(folderName)
                store.dispatch({
                    type: "updateWindow", windowKey: id, window: {
                        instructions: [
                            ...instructions, { path: path, instruction: instruction, output: "" }
                        ]
                    }
                });
                setInstructions([...instructions, { path: path, instruction: instruction, output: "" }])
                return
            case 'touch':
                let fileName = instruction.split(" ")[1]
                if (fileName === undefined) {
                    store.dispatch({
                        type: "updateWindow", windowKey: id, window: {
                            instructions: [
                                ...instructions, { path: path, instruction: instruction, output: "touch: missing operand" }
                            ]
                        }
                    });
                    setInstructions([...instructions, { path: path, instruction: instruction, output: "touch: missing operand" }])
                    return
                }
                Folder.getFolder(path).addFile(fileName)
                store.dispatch({
                    type: "updateWindow", windowKey: id, window: {
                        instructions: [
                            ...instructions, { path: path, instruction: instruction, output: "" }
                        ]
                    }
                });
                setInstructions([...instructions, { path: path, instruction: instruction, output: "" }])
                return
            case 'rm':
                let name = instruction.split(" ")[1]
                if (name === undefined) {
                    store.dispatch({
                        type: "updateWindow", windowKey: id, window: {
                            instructions: [
                                ...instructions, { path: path, instruction: instruction, output: "rm: missing operand" }
                            ]
                        }
                    });
                    setInstructions([...instructions, { path: path, instruction: instruction, output: "rm: missing operand" }])
                    return
                }
                if (Folder.getFolder(path).containsFolder(name)) {
                    Folder.getFolder(path).removeFolder(name)
                    store.dispatch({
                        type: "updateWindow", windowKey: id, window: {
                            instructions: [
                                ...instructions, { path: path, instruction: instruction, output: "" }
                            ]
                        }
                    });
                    setInstructions([...instructions, { path: path, instruction: instruction, output: "" }])
                    return
                }
                if (Folder.getFolder(path).containsFile(name)) {
                    Folder.getFolder(path).removeFile(name)
                    store.dispatch({
                        type: "updateWindow", windowKey: id, window: {
                            instructions: [
                                ...instructions, { path: path, instruction: instruction, output: "" }
                            ]
                        }
                    });
                    setInstructions([...instructions, { path: path, instruction: instruction, output: "" }])
                    return
                }
                store.dispatch({
                    type: "updateWindow", windowKey: id, window: {
                        instructions: [
                            ...instructions, { path: path, instruction: instruction, output: "rm: cannot remove '" + name + "': No such file or directory" }
                        ]
                    }
                });
                setInstructions([...instructions, { path: path, instruction: instruction, output: "rm: cannot remove '" + name + "': No such file or directory" }])
                return
            case 'cat':
                let fileName2 = instruction.split(" ")[1]
                if (fileName2 === undefined) {
                    store.dispatch({
                        type: "updateWindow", windowKey: id, window: {
                            instructions: [
                                ...instructions, { path: path, instruction: instruction, output: "cat: missing operand" }
                            ]
                        }
                    });
                    setInstructions([...instructions, { path: path, instruction: instruction, output: "cat: missing operand" }])
                    return
                }
                if (Folder.getFolder(path).containsFile(fileName2)) {
                    let file = Folder.getFolder(path).getFile(fileName2)
                    console.log(file)
                    store.dispatch({
                        type: "updateWindow", windowKey: id, window: {
                            instructions: [
                                ...instructions, { path: path, instruction: instruction, output: file.content }
                            ]
                        }
                    });
                    setInstructions([...instructions, { path: path, instruction: instruction, output: file.content }])
                    return
                }
                store.dispatch({
                    type: "updateWindow", windowKey: id, window: {
                        instructions: [
                            ...instructions, { path: path, instruction: instruction, output: "cat: " + fileName2 + ": No such file or directory" }
                        ]
                    }
                });
                setInstructions([...instructions, { path: path, instruction: instruction, output: "cat: " + fileName2 + ": No such file or directory" }])
                return
            case 'echo':
                let data2 = instruction.split(" ")[1]
                if (data2 === undefined) {
                    store.dispatch({
                        type: "updateWindow", windowKey: id, window: {
                            instructions: [
                                ...instructions, { path: path, instruction: instruction, output: "echo: missing operand" }
                            ]
                        }
                    });
                    setInstructions([...instructions, { path: path, instruction: instruction, output: "echo: missing operand" }])
                    return
                }
                store.dispatch({
                    type: "updateWindow", windowKey: id, window: {
                        instructions: [
                            ...instructions, { path: path, instruction: instruction, output: data2 }
                        ]
                    }
                });
                setInstructions([...instructions, { path: path, instruction: instruction, output: data2 }])
                return
            case 'resume':
                store.dispatch({
                    type: "addWindow",
                    window: {
                        type: "CVViewer",
                        id: Math.random().toString(36).substring(7),
                        size: { width: "80%", height: "80%" },
                        pos: { x: 50, y: 100 },
                    },
                });
                store.dispatch({
                    type: "updateWindow", windowKey: id, window: {
                        instructions: [
                            ...instructions, { path: path, instruction: instruction, output: "" }
                        ]
                    }
                });
                setInstructions([...instructions, { path: path, instruction: instruction, output: "" }])
                return
            case 'github':
                window.open("https://github.com/Mike17K/Kali-portofolio", "_blank");
                store.dispatch({
                    type: "updateWindow", windowKey: id, window: {
                        instructions: [
                            ...instructions, { path: path, instruction: instruction, output: "" }
                        ]
                    }
                });
                setInstructions([...instructions, { path: path, instruction: instruction, output: "" }])
                return
            case 'linkedin':
                window.location.href = window.open(
                    "https://www.linkedin.com/in/mike-kaipis/",
                    "_blank"
                );
                setInstructions([...instructions, { path: path, instruction: instruction, output: "" }])
                return
            case 'projects':
                // TODO fix the structure of the projects and filter by stars
                let projectsHTML = `
                <table className='projects'>
                    <thead>
                        <td>Project Name</td>
                        <td>Stars</td>
                        <td>Description</td>
                    </thead>
                `;
                // order by stars
                // sort by stars 
                fetch('https://api.github.com/users/Mike17K/repos', { method: 'GET' }).then(res => res.json()).then(data => {
                    const sortedRepos = data.sort((a, b) => {
                        // Sort by stargazers_count (descending)
                        if (b.stargazers_count !== a.stargazers_count) {
                            return b.stargazers_count - a.stargazers_count;
                        } else {
                            // If stargazers_count is the same, sort by updated_at (descending)
                            return new Date(b.updated_at) - new Date(a.updated_at);
                        }
                    });

                    // Now `sortedRepos` contains the repositories sorted by stargazers_count (descending)
                    // and if stargazers_count is the same, then sorted by updated_at (descending)
                    sortedRepos.forEach(repo => {
                        console.log(repo.name, repo.stargazers_count, repo.updated_at);
                    });

                    return sortedRepos;
                }).then(data => {
                    data.forEach(project => {
                        projectsHTML += `
                        <tr className='project'>
                            <td className="link"><a href='${project.html_url}' target='_blank'>${project.name}</a></td>
                            <td className="stars"><p>${project.stargazers_count}</p></td>
                            <td className="description">${project.description ? project.description : ""}</td>
                        </tr>`
                    });
                    projectsHTML += "</table>"

                    store.dispatch({
                        type: "updateWindow", windowKey: id, window: {
                            instructions: [
                                ...instructions, { path: path, instruction: instruction, output: projectsHTML }
                            ]
                        }
                    });
                    setInstructions([...instructions, { path: path, instruction: instruction, output: projectsHTML }])
                }
                )
                return
            case 'whoami':
                // TODO add image of me 
                let whoamiHTML = `
                <div class="container mx-auto p-4 max-w-xl">
        <h1 class="text-3xl text-[#000000] font-bold mb-4 text-center">Hello there!</h1>
        <p class="text-sm mb-6">
            My name is Michail Kaipis, an undergraduate student at the University of Patras studying computer science as
            my main field as an Electrical and Computer Engineer.
        </p>
        <p class="text-sm  mb-6">
            I am interested in a lot of areas of tech, so with all these years of continuous learning, as a fast
            learner, I have a lot of experience with software. From machine learning to full-stack web development, app
            development, devops, and more.
        </p>
        <p class="text-sm  mb-6">
            As a person who knows how to find solutions to any given problem, my goal is to find the optimal solution
            that is scalable, fast, and easy maintainable. <span class="font-bold text-blue-500">Algorithms and data
                structures are my strong points.</span>
        </p>
        <p class="text-sm  mb-6">
            I am highly interested in optimizations of tasks and automations. Robotics is a very interesting field for
            me, and I have loads of experience with Arduino, 3D printing, and control systems theory.
        </p>
        <p class="text-sm  mb-6">
            In my free time, you will find me working on personal projects, reading books, and working out. My side
            hobbies are chess, high jump, violin, coding fun projects, reading interesting books, and playing with
            Arduino chips.
        </p>
        <p class="text-sm  mb-6">
            <span class="font-bold text-blue-500">Team leadership, teamwork, and open-mindedness</span> are some things I
            gained from my 3-year experience of working voluntarily with the IT @EESTEC local and international team.
        </p>
        <p class="text-sm  mb-6">
            I am looking for opportunities to work with a team to contribute to meaningful projects with a clear common
            goal.
        </p>
        <p class="text-sm  mb-6">
            <span class="font-bold text-blue-500">Open to work with any new programming language or framework.</span>
        </p>
        <p class="text-sm  mb-6">
            Current knowledge of: HTML, CSS, JS, Java, Python, C, C++, C#, Cython, PHP, SQL, Swift, MATLAB, Bash, Dart,
            ASP, Verilog, and Prolog.
        </p>
        <p class="text-sm  mb-6">
            Currently working with APIs and coding WordPress plugins as an intern at Advento Marketers.
        </p>
        <p class="text-sm ">
            Feel free to visit my <a href="https://github.com/Mike17K" target="_blank"
                class="font-bold text-blue-500">GitHub profile here</a> 👌 or by typing 'github' in the terminal like a boss.
        </p>
    </div>
                `;
                store.dispatch({
                    type: "updateWindow", windowKey: id, window: {
                        instructions: [
                            ...instructions, { path: path, instruction: instruction, output: whoamiHTML }
                        ]
                    }
                });
                setInstructions([...instructions, { path: path, instruction: instruction, output: whoamiHTML }])
                return
            // case 'fetchme':
            //     return // TODO
            case 'boom':
                // TODO
                // rotate desktop 360
                let desktop = document.getElementById("desktop")
                desktop.classList.add("rotate-360")
                setTimeout(() => {
                    desktop.classList.remove("rotate-360")
                }, 1000);

                return
            case 'help':
                // TODO
                let helpHTML = `These shell commands are defined internally.<br/>  Type 'help' to see this list.<br/>
                <table className="help-commands my-2">
    <tr>
      <th>Command</th>
      <th>Description</th>
    </tr>
    <tr class="help-button">
      <td><span class="help-command">help</span></td>
      <td>Show this help</td>
    </tr>
    <tr class="help-button">
      <td><span class="help-command">resume</span></td>
      <td>Open my resume</td>
      </tr>
      <tr class="help-button">
        <td><span class="help-command">whoami</span></td>
        <td>Show my bio</td>
     </tr>    
    <tr class="help-button">
      <td><span class="help-command">github</span></td>
      <td>Open my GitHub profile</td>
    </tr>
    <tr class="help-button">
      <td><span class="help-command">linkedin</span></td>
      <td>Open my LinkedIn profile</td>
    </tr>
    <tr class="help-button">
      <td><span class="help-command">code</span></td>
      <td>Open VSCode</td>
    </tr>
    <tr class="help-button">
      <td><span class="help-command">projects</span></td>
      <td>Show my projects</td>
    </tr>
    <tr class="help-button">
      <td><span class="help-command">fetchme</span></td>
      <td>Fetch me</td>
    </tr>
    <tr class="help-button">
        <td><span class="help-command">cd</span></td>
        <td>Change directory, navigate within the treee</td>
    </tr>
  </table>
                <p className="text-[0.7rem]" >there are more "hidden" commands and more are comming!! ⚒️👷‍♂️</p>
                <br/>
                `;
                store.dispatch({
                    type: "updateWindow", windowKey: id, window: {
                        instructions: [
                            ...instructions, { path: path, instruction: instruction, output: helpHTML }
                        ]
                    }
                });
                setInstructions([...instructions, { path: path, instruction: instruction, output: helpHTML }])
                return
            case 'DrawApp':
                store.dispatch({
                    type: "addWindow", window: {
                        type: "DrawApp",
                        id: Math.random().toString(36).substring(7),
                        pos: { x: 50, y: 100 },
                    }
                });
                setInstructions([...instructions, { path: path, instruction: instruction, output: "" }])
                return
            default:
                store.dispatch({
                    type: "updateWindow", windowKey: id, window: {
                        instructions: [
                            ...instructions, { path: path, instruction: instruction, output: "" }
                        ]
                    }
                });
                setInstructions([...instructions, { path: path, instruction: instruction, output: "" }])
                return
        }
    } catch (error) {
        console.log(error)
    }

}
