import React from "react";
import Window from "../Window/Window";
import DrawAppViewPort from "./components/DrawAppViewPort/DrawAppViewPort";
import { useSelector } from "react-redux";

export default function DrawApp({ id, pos, size }) {
  const windows = useSelector((state) => state.windows);
  console.log("windowswindows" , windows);
  return (
    <Window
      id={id}
      pos={pos}
      init_size={size}
      icon={"/static/icons/icons8-linux-terminal-48.png"}
      headerText={`🏠 mike@portfolio: type 'help' for more info`}
      menu={
        <div className="flex gap-2 text-white select-none">
          <span className="text-[0.6rem]">File</span>
          <span className="text-[0.6rem]">Actions</span>
          <span className="text-[0.6rem]">Edit</span>
          <span className="text-[0.6rem] cursor-pointer">View</span>
          <span className="text-[0.6rem]">Help</span>
        </div>
      }
      MainView={()=><DrawAppViewPort />}
    />
  );
}