import React, { useContext, useEffect, useState } from "react";
import "./NavBar.css";
import { store } from "../../redux/store";
import { Theme } from "../../App";
import Terminal from "../Terminal/Terminal";
import ReactDOM from "react-dom";

export default function NavBar() {
  const { theme, setTheme } = useContext(Theme);

  const [volume, setVolume] = useState(0.4);
  const [desktopId, setDesktopId] = useState(0);
  const [connected, setConnected] = useState(true);
  const [time, setTime] = useState(null);
  const [IPv4, setIPv4] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(
        new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      );
    }, 1000);

    // fetch my ip
    fetch("https://geolocation-db.com/json/", { method: "GET" })
      .then((res) => res.json())
      .then((res) => setIPv4(res.IPv4))
      .catch((err) => console.log(err));

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (theme === "1") {
      setDesktopId(0);
    } else if (theme === "2") {
      setDesktopId(1);
    } else if (theme === "3") {
      setDesktopId(2);
    } else if (theme === "4") {
      setDesktopId(3);
    }
  }, []);

  useEffect(() => {
    if (desktopId === 0) {
      setTheme("1");
    } else if (desktopId === 1) {
      setTheme("2");
    } else if (desktopId === 2) {
      setTheme("3");
    } else if (desktopId === 3) {
      setTheme("4");
    }
  }, [desktopId]);

  function openTerminalSortcut(e) {
    // open a terminal if the keys are hold down: ctrl + alt + t
    if (!(e.ctrlKey && e.altKey && e.key === "t")) return;

    const key = Math.random().toString(36).substring(7);
    store.dispatch({
      type: "addWindow",
      window: {
        type: "Terminal",
        id: key,
        pos: { x: 150, y: 100 },
        current_path: "/home/kali",
        instructions: [],
      },
    });
  }

  useEffect(() => {
    document.addEventListener("keydown", openTerminalSortcut);
    return () => {
      document.removeEventListener("keydown", openTerminalSortcut);
    };
  }, []);

  const iconsHeight = 20;
  const iconsWidth = 20;

  return (
    <>
      <div className="navbar top-0 left-0 fixed w-full text-white flex items-center justify-center absolute z-10">
        <div className={`w-full h-[${iconsHeight}px] flex justify-between`}>
          <div className="float-left flex">
            <img
              className="nav-icon start-button rounded"
              alt="Start"
              src="/static/icons/icons8-kali-linux.svg"
              width={iconsWidth}
              height={iconsHeight}
            />
            <span className="mx-1 border w-[1px]"></span>
            <img
              className="nav-icon"
              alt="Desktop"
              src="/static/icons/icons8-pro-display-xdr-48.png"
              width={iconsWidth}
              height={iconsHeight}
            />
            <img
              className="nav-icon"
              alt="Files"
              src="/static/icons/icons8-desktop-folder-48.png"
              width={iconsWidth}
              height={iconsHeight}
            />
            <img
              className="nav-icon"
              alt="FireFox"
              src="/static/icons/icons8-firefox-24.png"
              width={iconsWidth}
              height={iconsHeight}
            />
            <img
              className="nav-icon"
              alt="Terminal"
              src="/static/icons/icons8-linux-terminal-48.png"
              width={iconsWidth}
              height={iconsHeight}
              onClick={(e) => {
                const key = Math.random().toString(36).substring(7);
                store.dispatch({
                  type: "addWindow",
                  window: {
                    type: "Terminal",
                    id: key,
                    pos: { x: 150, y: 100 },
                    current_path: "/home/kali",
                    instructions: [],
                  },
                });
              }}
            />

            <span className="mx-1 border w-[1px]"></span>
            <div
              onClick={(e) => setDesktopId(0)}
              className={`nav-icon desktop-choice text-[0.6rem] font-thin ${
                desktopId === 0 ? "selected" : ""
              } flex justify-center items-center`}
            >
              1
            </div>
            <div
              onClick={(e) => setDesktopId(1)}
              className={`nav-icon desktop-choice text-[0.6rem] font-thin ${
                desktopId === 1 ? "selected" : ""
              } flex justify-center items-center`}
            >
              2
            </div>
            <div
              onClick={(e) => setDesktopId(2)}
              className={`nav-icon desktop-choice text-[0.6rem] font-thin ${
                desktopId === 2 ? "selected" : ""
              } flex justify-center items-center`}
            >
              3
            </div>
            <div
              onClick={(e) => setDesktopId(3)}
              className={`nav-icon desktop-choice text-[0.6rem] font-thin ${
                desktopId === 3 ? "selected" : ""
              } flex justify-center items-center`}
            >
              4
            </div>
            <span className="mx-1 border w-[1px]"></span>
          </div>

          <div className="float-right flex">
            <img
              className="nav-icon-right "
              alt="Web Connection"
              src={`/static/icons/icons8-${
                connected ? "connected" : "disconnected"
              }-50.png`}
              width={iconsWidth}
              height={iconsHeight}
            />
            <span className="mx-1 font-thin text-[0.6rem] flex justify-center items-center">
              {IPv4}
            </span>
            <img
              className="nav-icon-right"
              alt="Sound"
              src={`
                ${
                  volume === 0
                    ? "/static/icons/icons8-mute-50.png"
                    : volume < 0.7
                    ? "/static/icons/icons8-low-volume-50.png"
                    : "/static/icons/icons8-volume-64.png"
                }`}
              width={iconsWidth}
              height={iconsHeight}
            />
            <img
              className="nav-icon-right "
              alt="notifications"
              src={`/static/icons/icons8-notifications-78.png`}
              width={iconsWidth}
              height={iconsHeight}
            />
            <span className="mx-[2px] font-thin text-[0.6rem] flex justify-center items-center">
              {time}
            </span>

            <span className="mx-1 border w-[1px]"></span>
            <img
              className="nav-icon-right"
              alt="Lock"
              src="/static/icons/icons8-lock-60.png"
              width={iconsWidth}
            />
            <img
              className="nav-icon-right"
              alt="logout"
              src="/static/icons/icons8-logout-rounded-50.png"
              width={iconsWidth}
            />
          </div>
        </div>
      </div>
    </>
  );
}
