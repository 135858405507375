import React from "react";
import Window from "../Window/Window";

import './VSCode.css';

export default function VSCode({ id, pos, size }) {
  const srcUrl = 'https://github1s.com/Mike17K/WebChess/blob/main/';
  return (
    <Window
      id={id}
      pos={pos}
      init_size={size || { width: "1000px", height: "800px" }}
      icon={"/static/icons/icons8-linux-terminal-48.png"}
      headerText={`kali@kali`}
      menu={
        <div className="flex ml-2 gap-2 h-[15px] text-[9px] text-white select-none">
          <span className="text-[#ffffff] text-[0.6rem]">File</span>
          <span className="text-[#ffffff] text-[0.6rem]">Actions</span>
          <span className="text-[#ffffff] text-[0.6rem]">Edit</span>
          <span className="text-[#ffffff] text-[0.6rem]">View</span>
          <span className="text-[#ffffff] text-[0.6rem]">Help</span>
        </div>
      }
      MainView={() => (
        <>
          <div className="w-full h-full">
            <iframe
              src={srcUrl}
              title="VSCode"
              className="vscView w-full text-[8px]"
              width="1000px"
              height="800px"
            />
          </div>
        </>
      )}
    />
  );
}
