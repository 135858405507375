import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSubscriptionEnhancer from './createSubscriptionEnhancer';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './rootReducer';

const persistConfig = {
  key: 'kali-portofolio-storage',
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const loggerMiddleware = (store) => (next) => (action) => {
  console.log('Action:', action);
  const result = next(action);
  console.log('State:', store.getState());
  
  return result;
};

// Compose multiple store enhancers into a single function
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(loggerMiddleware, thunkMiddleware),
    createSubscriptionEnhancer()
  )
);

const persistor = persistStore(store);

export { store, persistor };
