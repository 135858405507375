import { combineReducers } from 'redux';

//import fileSystem, {Folder , File } from './fileSystemINIT.js'
import root from './fileSystemINITv2.js'

const INITIAL_STATE = {
    fileSystem: root,
    windows: [{
        id: 0,
        current_path: "/home/kali",
        instructions: [
            {
                instruction: "hit help for more help 💥",
                output: "",
                path: "/home/kali",
            }
        ],
        pos: { x: 20, y: 50 },
        type: "Terminal"
    },{
        id: 1,
        pos: { x: 40, y: 50 },
        type: "DrawApp",
    }],
};

const fileSystemReducer = (state = INITIAL_STATE.fileSystem, action) => {
    switch (action.type) {
        case "addFolder":
            return {
                ...state, fileSystem: state.fileSystem.addFolder(action.folderName),
            };
        case "removeFolder":
            return {
                ...state, fileSystem: state.fileSystem.removeFolder(action.folderName),
            };
        case "addFile":
            return {
                ...state, fileSystem: state.fileSystem.addFile(action.fileName),
            };
        case "removeFile":
            return {
                ...state, fileSystem: state.fileSystem.removeFile(action.fileName),
            };
        case "resetFileSystem":
            return {
                ...state, fileSystem: INITIAL_STATE.root,
            };

        default: return state;
    }
}

const windowsReducer = (state = INITIAL_STATE.windows, action) => {
    switch (action.type) {
        case "addWindow":
            return [...state, action.window];
        case "removeWindow":
            return [...state.filter(c => c.id !== action.windowKey)];
        case "resetWindows":
            return INITIAL_STATE.windows
        case "updateWindow":
            return [...state.map(c => {
                if (c.id === action.windowKey) {
                    return { ...c, ...action.window };
                }
                return c;
            })];
        default: return state;
    }
}

const rootReducer = combineReducers({
    fileSystem: fileSystemReducer,
    windows: windowsReducer,
});

export default rootReducer;