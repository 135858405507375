// 2-desctop file
// 3-file window // movable scaleable
// 4-vsc window

// 4-terminal
// loading animation
import React, { createContext, useState } from 'react';
import NavBar from './components/NavBar/NavBar';
import Desktop from './components/Desktop/Desktop';
import QuickAccessBar from './components/QuickAccessBar/QuickAccessBar';

import {store} from "./redux/store"; // Import your Redux store
import { Provider } from "react-redux";


export const Theme = createContext();


export default function App() {
  const [theme, setTheme] = useState("3");
  return (
    <Provider store={store}>

    <Theme.Provider value={{ theme, setTheme }} >
      <div className="w-[100vw] h-[100vh]">
        <NavBar setTheme={setTheme} />
        <Desktop theme={theme} />
        <QuickAccessBar />
      </div>
    </Theme.Provider>

    </Provider>

  );
}