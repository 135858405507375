import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Terminal from "../Terminal/Terminal";
import VSCode from "../VSCode/VSCode";
import { store } from "../../redux/store";
import { Theme } from "../../App";
import CVViewer from "../CVViewer/CVViewer";

import "./Desktop.css";
import DrawApp from "../DrawApp/DrawApp";

export default function Desktop() {
  const [wallpaper, setWallpaper] = useState("wallpaper-1.jpg");
  const { theme } = useContext(Theme);
  const [windowsState, setWindowsState] = useState(
    store.getState().windows || []
  ); // State to manage the windows

  useEffect(() => {
    console.log("theme: ", theme);
    if (theme === "1") {
      setWallpaper("wallpaper-1.jpg");
    } else if (theme === "2") {
      setWallpaper("wallpaper-2.jpg");
    } else if (theme === "3") {
      setWallpaper("wallpaper-3.png");
    } else if (theme === "4") {
      setWallpaper("wallpaper-4.jpg");
    }
  }, [theme]);

  useEffect(() => {
    const unsubscribe = store.subscribe((action) => {
      if (action.type === "addWindow") {
        const newWindows = store.getState().windows; // Use the updated state from the store
        setWindowsState(newWindows);
      } else if (action.type === "resetWindows") {
        const newWindows = store.getState().windows; // Use the updated state from the store
        setWindowsState(newWindows);
      }
    });

    return () => {
      unsubscribe(); // Unsubscribe when the component unmounts to avoid memory leaks
    };
  }, []);

  console.log("desktop render: ", windowsState);
  return (
    <div
      id="desktop"
      className="desktop w-full h-full absolute"
      style={{
        backgroundImage: `url("/static/wallpapers/${wallpaper}")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className="top-[30px] relative overflow-hidden w-full h-full"
        style={{ height: "calc(100vh - 30px)" }}
      >
        {windowsState.map((component) => {
          const { id, pos, size, type } = component;
          console.log("render window: ", id, pos, size, type);

          if (type === "Terminal") {
            return (
              <Terminal
                key={id}
                id={id}
                pos={pos}
                size={size}
                current_path={component.current_path}
                init_instructions={component.instructions}
              />
            );
          } else if (type === "VSCode") {
            return <VSCode key={id} id={id} pos={pos} size={size} />;
          } else if (type === "CVViewer") {
            return <CVViewer key={id} id={id} pos={pos} size={size} />;
          } else if (type === "DrawApp") {
            return <DrawApp key={id} id={id} pos={pos} size={size} />;
          }

          return null; // Add more types of windows and return appropriate components
        })}
      </div>
    </div>
  );
}
