import React from "react";
import Window from "../Window/Window";

import './CVViewer.css';

export default function CVViewer({ id, pos, size }) {
  return (
    <Window
      id={id}
      pos={pos}
      init_size={size || { width: "50%", height: "80%" }}
      icon={"/static/icons/icons8-linux-terminal-48.png"}
      headerText={`Michail Kaipis Resume.pdf - Google Drive`}
      menu={
        <div className="flex gap-2 h-[15px] text-[9px] text-white select-none">
          <span className="text-[#ffffff] text-[0.6rem]">File</span>
          <span className="text-[#ffffff] text-[0.6rem]">Actions</span>
          <span className="text-[#ffffff] text-[0.6rem]">Edit</span>
          <span className="text-[#ffffff] text-[0.6rem]">View</span>
          <span className="text-[#ffffff] text-[0.6rem]">Help</span>
        </div>
      }
      MainView={() => (
        <>
          <iframe
            title="CV"
            src="https://drive.google.com/file/d/1j05wVAoChp18bilG1PYB_NwHfTOjcTX2/preview"
            className="pdfview absolute w-full select-none"
            ></iframe>
        </>
      )}
      />
  );
}
