// createSubscriptionEnhancer.js
const createSubscriptionEnhancer = () => (createStore) => (reducer, initialState, enhancer) => {
    const store = createStore(reducer, initialState, enhancer);
    let subscribers = [];
  
    store.subscribe = (callback) => {
      subscribers.push(callback);
      return () => {
        subscribers = subscribers.filter((sub) => sub !== callback);
      };
    };
  
    const dispatchWithSubscribers = (action) => {
      store.dispatch(action);
      subscribers.forEach((sub) => sub(action));
    };
  
    return {
      ...store,
      dispatch: dispatchWithSubscribers,
    };
  };
  
  export default createSubscriptionEnhancer;
  