import React, { useContext, useEffect, useRef, useState } from "react";
import { instructionHandler, tabPressed } from "./instructionHandler";
import parse from "html-react-parser";
import { Theme } from "../../../../App";
import "./TerminalViewPort.css";

const colors = {
  1: "theme-1",
  2: "theme-2",
  3: "theme-3",
  4: "theme-4",
};

function Instrunction({ index, path, instruction, output, fireInstruction }) {
  // console.log("a: ",output)
  //help-button
  //help-command

  useEffect(() => {
    const helpButtons = document.querySelectorAll(".help-button");

    helpButtons.forEach((button) => {
      const clonedButton = button.cloneNode(true);
      button.parentNode.replaceChild(clonedButton, button);
    });

    const helpButtonsCloned = document.querySelectorAll(".help-button");
    helpButtonsCloned.forEach((button) => {
      button.addEventListener("click", (e) => {
        const helpCommand = button.querySelector(".help-command").innerHTML;
        fireInstruction(helpCommand);
      });
    });
  }, []);

  return (
    <div key={index}>
      <div className="flex gap-1">
        <span className="text-[#00ff00] text-[0.6rem]">root@kaipis</span>
        <span className="text-[#00ff00] text-[0.6rem]">:</span>
        <span className="text-[#00ff00] text-[0.6rem]">{path}</span>
        <span className="text-[#00ff00] text-[0.6rem]">$</span>
        <span className="text-[0.6rem]">{instruction}</span>
      </div>
      <span className="text-[0.6rem]">{parse(output || "")}</span>
    </div>
  );
}

export default function TerminalViewPort({
  id,
  inputRef,
  listEndRef,
  path,
  setPath,
  instructions,
  setInstructions,
  fireInstruction,
}) {
  const { theme } = useContext(Theme);

  const instruction_counter = useRef(0);

  function onSubmit(e) {
    instruction_counter.current = 0;
    e.preventDefault();
    instructionHandler({
      id,
      instruction: inputRef.current.value,
      path,
      setPath,
      inputRef,
      instructions,
      setInstructions,
    });

    inputRef.current.value = "";
    listEndRef.current.scrollIntoView();
    inputRef.current.focus();
  }
  useEffect(() => {
    inputRef.current.focus();
    inputRef.current.scrollIntoView();
  }, [instructions]);

  
  function handleTab(e) {
    const input = inputRef.current;
    if (e.key === 'Tab') {
      e.preventDefault();
      let value = inputRef.current.value;
      let lastWord = value.split(' ')[value.split(' ').length - 1];
      let commands = ['cd', 'cat', 'rm'];
      let command = value.split(' ')[0];
      if (commands.includes(command)) {
        input.value = value.replace(lastWord, '');

        // find the names of the files and folders of the current directory
        console.log('input word: ', lastWord, path);
        input.value += tabPressed({ path: path, command, string: lastWord });
        return;
      }

      input.value += '    ';
    }
  }
  
  function handleUp(e) {
    const input = inputRef.current;
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      // this loop is to skip empty instructions
      if (instructions.length === 0) return;
  
      instruction_counter.current -= 1;
      while (true) {
        instruction_counter.current += 1;
        if (instruction_counter.current >= instructions.length - 1) {
          instruction_counter.current= instructions.length - 1
          break;
        }
        if (instructions[instructions.length - 1 - instruction_counter.current].instruction !== '') {
          break;
        }
      }
      
      input.value = instructions[instructions.length - 1 - instruction_counter.current].instruction;
      instruction_counter.current += 1;
    }
  }
  
  function handleDown(e) {
    const input = inputRef.current;
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      // this loop is to skip empty instructions
      if (instructions.length === 0) return;
  
      while (true) {
        if (instruction_counter.current <=0 ) {
          instruction_counter.current = 0;
          break;
        }
        instruction_counter.current -= 1;
        if (instructions[instructions.length - 1 - instruction_counter.current].instruction !== '') {
          break;
        }
      }
  
        input.value = instructions[instructions.length - 1 - instruction_counter.current].instruction;
        
    }
  }
  
  
  useEffect(() => {
    const input = inputRef.current;
    input.addEventListener('keydown', handleTab);
    input.addEventListener('keydown', handleUp);
    input.addEventListener('keydown', handleDown);
    
    
    return () => {
      input.removeEventListener('keydown', handleTab);
      input.removeEventListener('keydown', handleUp);
      input.removeEventListener('keydown', handleDown);
    };
  }, [path,instructions]); // Make sure to include path as a dependency

  
  return (
    <div
      className={`absolute top-[2.4rem] bottom-1 left-2 right-2 overflow-y-scroll ${colors[theme]}`}
    >
      <div
        className="w-full h-full leading-3"
        onClick={(e) => inputRef.current.focus()}
      >
        {instructions &&
          instructions.map(({ path, instruction, output }, index) => {
            return (
              <Instrunction
                index={index}
                path={path}
                instruction={instruction}
                output={output}
                fireInstruction={fireInstruction}
              />
            );
          })}

        <Instrunction
          index="input"
          path={path}
          instruction={
            <>
              <form
                action=""
                className="w-full text-[0.6rem]"
                onSubmit={onSubmit}
              >
                {/* <span className='output'>{inputString}</span>  FIX TODO */}
                <input
                  ref={inputRef}
                  className="w-full bg-transparent outline-none"
                  type="text"
                />
                <button type="submit"></button>
              </form>
            </>
          }
          fireInstruction={fireInstruction}
        />

        <div ref={listEndRef}></div>
      </div>
    </div>
  );
}
