export class File{
    constructor(name,content=""){
        this.name = name;
        this.path = "/";
        this.content = content;
    }
}

export class Folder{
    static root = new Folder("/");

    constructor(name){
        this.name = name;
        this.folders = [];
        this.files = [];
        this.path = "/";
    }

    addFolder(name){
        const folder = new Folder(name);
        folder.path = `${this.path}/${folder.name}`
        this.folders.push(folder);
        return this;
    }

    addFile(name,content=""){
        const file = new File(name,content);
        file.path = `${this.path}/${file.name}`
        this.files.push(file);
        return this;
    }

    removeFolder(name){
        this.folders = this.folders.filter(c => c.name !== name);
    }

    removeFile(name){
        this.files = this.files.filter(c => c.name !== name);
    }
    
    containsFolder(name){
        return this.folders.some(c => c.name === name);
    }

    containsFile(name){
        return this.files.some(c => c.name === name);
    }

    getFile(name){
        return this.files.find(c => c.name === name);
    }

    static getFolder(path){ // absolute path // relative path can be transformed to absolute path before calling this function
        if(path === "/") return Folder.root;
        
        let currentFolder = Folder.root;
        
        const folders = path.split("/");
        
        for(let i = 1; i < folders.length; i++){
            // if folders[i] === ".." then go to the parent folder TODO
            if(!currentFolder.containsFolder(folders[i])){
                console.log("no such folder")
                return null;
            }
            
            currentFolder = currentFolder.folders.find(c => c.name === folders[i]);
        }
        return currentFolder;    
    
    }





    // optional

    getFoldersNames(){
        return this.folders.map(c => c.name);
    }

    getFilesNames(){
        return this.files.map(c => c.name);
    }



}


Folder.root.addFolder("home").addFolder("bin").addFolder("etc");

Folder.getFolder("/home").addFolder("kali").addFolder("guest");

Folder.getFolder("/home/kali").addFolder("Desktop").addFolder("Documents").addFolder("Downloads");
Folder.getFolder("/home/kali/Desktop").addFile("easterEggOrNot.txt","why u opened me bro??? i want to sleep go away, lol every dude is awaking me i cant stand it... 😒😒😒😒").addFile("PickOne.txt","concrats u are a true programmer u know about array indexing, some tip for the future: stop coding and let me do it for you give me your money and live your life! 👌👌👌 \n \n also there is a hidden command: 'boom' lol").addFile("NotMe.txt","mmm that was brave well go <a href='https://www.youtube.com/watch?v=SjHUb7NSrNk' className='text-[#ffff00] underline'>here</a> and check what happens!!");
Folder.getFolder("/home/guest").addFolder("Desktop").addFolder("Documents").addFolder("Downloads");
Folder.getFolder("/home/guest/Desktop").addFile("gambleAndOpenMe.txt", "lost, u are loser how do u feel. NO. Go king! life is yours. LOL nope u are loser. OR NOT").addFile("DontDoItFethon.txt","thats corect! u wone a chat gpt's joke: \n Why don't scientists trust atoms? Because they make up everything! ... yea ok keep going now xd").addFile("JustDoIt.txt", "hire me bro dont think about it 🤐");

export default Folder.root;