import React, { useState } from "react";
import "./QuickAccessBar.css";
import { store } from "../../redux/store";

export default function QuickAccessBar() {
  const [active, setActive] = useState(null);
  const popups = [
    {
      name: "Home",
      icon: "/static/icons/home-button.png",
      action: () => {
        store.dispatch({
          type: "resetWindows",
        });
      },
    },
    {
      name: "Code",
      icon: "/static/icons/vsc.png",
      action: () => {
        const key = Math.random().toString(36).substring(7);
        store.dispatch({
          type: "addWindow",
          window: {
            type: "VSCode",
            id: key,
            size: { width: "80%", height: "80%" },
            pos: { x: 50, y: 100 },
          },
        });
      },
    },
    {
      name: "GitHub",
      icon: "/static/icons/github.png",
      action: () => {
        // make the portofolio repo public TODO
        window.open("https://github.com/Mike17K", "_blank");
      },
    },
    {
      name: "LinkedIn",
      icon: "/static/icons/linkedin.png",
      action: () => {
        window.location.href = window.open(
          "https://www.linkedin.com/in/mike-kaipis/",
          "_blank"
        );
      },
    },
    {
      name: "CV",
      icon: "/static/icons/curriculum-vitae.png",
      action: () => {
        // window.location.href = window.open(
        //   "https://drive.google.com/file/d/18aZdJCMnUptcilZ-s59evuzNkjFujcz6/preview",
        //   "_blank"
        // );

        const key = Math.random().toString(36).substring(7);
        store.dispatch({
          type: "addWindow",
          window: {
            type: "CVViewer",
            id: key,
            size: { width: "80%", height: "80%" },
            pos: { x: 50, y: 100 },
          },
        });
      },
    },
  ];

  return (
    <div className="absolute bottom-8 right-0 left-0 flex justify-center items-center">
      <div className="list z-[1000] mr-2 cursor-pointer">
        {popups.map((popup, index) => {
          return (
            <div
              key={index}
              className="flex flex-col items-center justify-center"
            >
              {active === index && (
                <div className="description absolute bottom-[3rem]">
                  {popups[active].name}
                </div>
              )}
              <img
                src={popup.icon}
                alt={popup.name}
                className="w-[0.5rem] h-[0.5rem] object-contain"
                onMouseEnter={() => setActive(index)}
                onMouseLeave={() => setActive(null)}
                onClick={() => {
                  popup.action();
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
