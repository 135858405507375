import React, { useRef, useState } from 'react';
import {store} from '../../redux/store';

let dragOffset = { x: 0, y: 0 };

const withDraggable = (WrappedComponent) => {
  return function DraggableComponent(props) {

    const handleDragStart = (event) => {
      event.stopPropagation();
      const { clientX, clientY } = event;
      const rect = props.windowRef.current.getBoundingClientRect();

        dragOffset = {
            x: clientX - rect.left,
            y: clientY - rect.top,
        };


      document.addEventListener('mousemove', handleDrag);
      document.addEventListener('mouseup', handleDragEnd);
    };

    const handleDrag = (event) => {
      
      event.stopPropagation();
      const { clientX, clientY } = event;
      const newLeft = clientX - dragOffset.x;
      const newTop = clientY - dragOffset.y - 30;
      
      document.body.style.cursor = 'grabbing';

      props.windowRef.current.style.left = `${newLeft}px`;
      props.windowRef.current.style.top = `${newTop}px`;
      props.windowRef.current.style.transition = 'none';

      store.dispatch({
        type: "updateWindow",
        windowKey: props.id,
        window: { pos: {x:newLeft,y:newTop} },
      });
    };
    
    const handleDragEnd = (event) => {
      document.body.style.cursor = 'default';
      props.windowRef.current.style.transition = 'all 0.3s ease-in-out';
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', handleDragEnd);
    };

    return (
      <div
        // ref={props.windowRef}
        onMouseDown={handleDragStart}
        className='w-full h-full'
      >
        <WrappedComponent {...props} />
      </div>
    );
  };
};

export default withDraggable;
