import React, { useRef, useState } from "react";
import TerminalViewPort from "./components/TerminalViewPort/TerminalViewPort";
import { instructionHandler } from "./components/TerminalViewPort/instructionHandler";

import Window from "../Window/Window";

function Terminal({ id, pos, size, current_path, init_instructions }) {
  const inputRef = useRef(null);
  const listEndRef = useRef(null);
  const { inputString, setInputString } = useState("");
  const [path, setPath] = useState(current_path || "/home/kali");
  const [instructions, setInstructions] = useState(init_instructions || []);

  function fireInstruction(instruction) {
    instructionHandler({
      id,
      instruction: instruction,
      path,
      setPath,
      inputRef,
      instructions,
      setInstructions,
    });
    inputRef.current.value = "";
    listEndRef.current.scrollIntoView();
    inputRef.current.focus();
  }

  return (
    <Window
      id={id}
      pos={pos}
      init_size={size}
      icon={"/static/icons/icons8-linux-terminal-48.png"}
      headerText={`🏠 mike@portfolio: type 'help' for more info`}
      menu={
        <div className="flex gap-2 text-white select-none">
          <span className="text-[0.6rem]">File</span>
          <span className="text-[0.6rem]">Actions</span>
          <span className="text-[0.6rem]">Edit</span>
          <span className="text-[0.6rem]">View</span>
          <span
            className="text-[0.6rem] cursor-pointer"
            onClick={() => fireInstruction("help")}
          >
            Help
          </span>
        </div>
      }
      MainView={() => (
        <TerminalViewPort
          id={id}
          inputRef={inputRef}
          listEndRef={listEndRef}
          path={path}
          setPath={setPath}
          instructions={instructions}
          setInstructions={setInstructions}
          fireInstruction={fireInstruction}
        />
      )}
    />
  );
}

export default Terminal;
